.ant-menu-horizontal:not(.ant-menu-dark) {
  margin-top: -1px;

  & > .ant-menu-item {
    margin-left: 0;
    margin-right: 0;
    border: none;

    &:hover {
      border: none;
    }

    & > a {
      display: inline-block;
      padding-left: 20px;
      padding-right: 20px;

      &.active {
        background-color: #f5f5f5;
        color: #1890ff;
      }
    }
  }

  & > .ant-menu-item-selected {
    border-bottom: none;
    background-color: #f5f5f5;

    &:hover {
      border-bottom: none;
    }
  }
}
