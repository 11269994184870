.cms-filter {
  .ant-popover-inner-content {
    padding: 20px;
  }

  .filter-item {
    margin-bottom: 16px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      width: 100%;
      text-align: left;
    }
  }
}
