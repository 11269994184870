@sunset-orange: #fff7e6;

.set-retriever-modal {
  .ant-modal-content {
    background: #f0f2f5;
  }
}

.suggestion {
  &-popover {
    max-width: 345px;

    .ant-popover-inner {
      background-color: @sunset-orange;
    }

    .ant-popover-content {
      .ant-popover-arrow {
        border-top-color: @sunset-orange;
        border-left-color: @sunset-orange;
      }
    }
  }

  &__button {
    &--delete {
      color: #ad4e00;
      font-size: 16px;
      padding: 0;
    }
  }

  &__icon {
    color: #fa8c16;
    font-size: 18px;

    &__address {
      float: right;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      margin: 8px 0;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
    }

    &-icon {
      font-size: 12px;
      color: #000;
      margin-right: 4px;
    }
  }
}

.relate-modal {
  &__wrapper {
    .ant-modal-content {
      background-color: #f5f5f5;
    }
  }

  &__content {
    padding-bottom: 150px;

    .ant-modal-close {
      position: fixed;
      right: 3%;
    }
  }

  &__footer {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(25px);
    transition: all 0.5s;

    &-title {
      &.ant-typography {
        color: #fff;
      }
    }

    &-icon {
      color: #fff;
      font-size: 18px;
    }

    &--inner {
      width: 85%;
      margin: 0 auto;
      padding-top: 12px;
    }

    &.is-toggled {
    }
  }

  &__close-icon {
    color: #000;
    font-size: 20px;
  }
}

.create-report-modal {
  .ant-modal-body {
    padding: 0px;
  }
}
