.ant-list-pagination {
  margin-bottom: 47px;

  .left {
    text-align: left;
  }
}

.form-list {
  &-item {
    &.ant-list-item {
      border-radius: 2px;
      background-color: #fff;
      margin-bottom: 24px;
      border-bottom: none;
      padding: 16px 16px 16px 20px;
    }

    &__title {
      margin-bottom: 0;
    }

    &__date {
      color: #262626;
    }

    &__icon {
      font-size: 21px;
      color: #bfbfbf;
    }

    &--current {
      color: #389e0d;
    }
  }

  &__add-icon {
    font-size: 11px;
  }
}

.suggestion-list {
  &__item {
    margin-bottom: 16px;

    &-title {
      color: #262626;
      font-size: 16px;
      margin-right: 8px;
    }

    &-name {
      color: #262626;
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: 600;
    }
  }
}
