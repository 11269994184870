.message {
  .ant-card-body {
    display: flex;
    align-items: center;
    padding: 14px 24px;
  }
}

.user-card {
  border-radius: 2px;
  background-color: #fff;
  padding: 24px;
  margin-bottom: 24px;

  &.small {
    padding: 16px;
  }

  h3.user-card__title {
    margin-bottom: 0;
    min-height: 27px;
  }

  .user-card__title-sub {
    margin-bottom: 8px;
  }

  &__avatar {
    background-color: #f5f5f5;

    &--icon {
      color: #bfbfbf;
    }
  }
}

.user-info-card {
  max-width: 630px;
  min-width: 630px;
  margin: 24px;

  & > .ant-card-body {
    padding: 48px;
  }
}

.info-card {
  margin-bottom: 24px;

  &__header {
    margin-bottom: 16px;

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__button-collapse {
    margin-left: 5px;
    cursor: pointer;
    color: #1890ff;
  }

  &__title {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
    margin-bottom: 4px;

    &--sub {
      color: #595959;
      margin-bottom: 16px;
    }
  }

  &__row {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 8px;

    &--name {
      font-size: 12px;
      font-weight: 600;
      color: #1890ff;
      margin-bottom: 4px;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__col {
    margin-right: 32px;
    display: inline-flex;
    flex-direction: column;

    &--label {
      color: #8c8c8c;
      font-size: 12px;
    }

    &--value {
      font-size: 16px;
      color: #000;
      margin-bottom: 4px;
      min-height: 25px;
    }
  }

  &__heading {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    color: #262626;
  }

  &__tag {
    white-space: normal;

    & > .ant-typography {
      margin-bottom: 0;
    }
  }
  &__id {
    a {
      margin-right: 8px;
    }
  }
}

.profile-card {
  margin-bottom: 24px;

  &__head {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 24px;
    font-size: 16px;
  }

  &__body {
    display: flex;
    font-size: 16px;

    div[class*='table'] {
      margin-bottom: 0;
    }
  }

  &__content {
    flex: 1;
    padding: 24px;
    margin-left: 2px;
    background: #ffffff;

    &:first-child {
      margin-left: 0;
    }
  }

  &__label {
    color: #8c8c8c;
  }

  &__value {
    font-weight: 600;
    color: #262626;
  }

  &__divider {
    border-left: 1px solid #bfbfbf;
    height: 1.2em;
  }
}

.exception-log-card {
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-list-item {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .ant-typography + h4.ant-typography {
    margin-top: 12px;
  }

  .new-value {
    .different {
      background: #c7f4a8;
    }
  }

  .old-value {
    .different {
      background: #fbaeae;
    }
  }

  &__date {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  &__text {
    font-size: 14px;
  }
  &__reasons-title,
  &__goals-title {
    font-weight: 900;
    font-size: 18px;
  }
  .ant-typography {
    margin-bottom: 0;
  }

  &__tag {
    display: flex;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: 14px;

    &-text {
      font-weight: 600;

      &.link {
        color: #1890ff;
      }
    }

    &--small {
      padding: 0 7px;
      font-size: 12px;
      font-weight: 400;
      margin-top: 0;
    }
  }
  &__case-summary-text {
    font-weight: 400;
    font-size: 14px;
  }

  h4 {
    line-height: 1.5;
  }
}

.activity-log-card {
  .ant-card-body {
    padding: 12px 24px;
  }

  &__text {
    font-size: 14px;
    color: #8c8c8c;
  }

  &__title {
    color: #262626;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
  }

  &__reason {
    font-size: 14px;
    color: #595959;
  }

  &__bottom {
    display: none;

    &.open {
      display: block;
    }

    .ant-divider {
      margin: 10px 0px;
    }
  }

  &__field {
    label {
      display: block;
    }

    &--new {
      &:disabled {
        color: #0050b3 !important;
      }
    }

    input {
      font-size: 14px;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #000;
    width: 28px;
    height: 28px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }

  button {
    font-size: 14px;
  }
}

.import_log_card {
  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    margin-right: 16px;
  }
  &__title_id {
    color: #bfbfbf;
  }
  &__file_name {
    font-size: 20px;
  }
  &__count_info {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &__title {
      margin-right: 8px;
      font-size: 14px;
      color: #8c8c8c;
    }
    &__value {
      color: #262626;
      font-size: 14px;
      &_negative {
        color: #820014;
      }
      &_positive {
        color: #237804;
      }
    }
  }
}

.generate-card-colors(@colors, profile-card);
