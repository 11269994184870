.select-icon {
  &--default {
    font-size: 14px;
    color: #8c8c8c;
  }
}

//.ant-select-arrow,
//.ant-select-clear {
//  top: 46%;
//  right: 18px;
//}

.ant-input-number,
.ant-picker {
  width: 100%;
}

.ant-radio-wrapper {
  &.large {
    .ant-radio-inner {
      width: 24px;
      height: 24px;

      &:after {
        width: 12px;
        height: 12px;
        top: 5px;
        left: 5px;
      }
    }
  }
}

.upload-field {
  .ant-upload {
    width: 100%;
  }

  &__input {
    input {
      color: #1890ff;
    }
  }

  &__button {
    background-color: #e6f7ff;
  }
}

.ant-input-disabled,
.ant-picker-disabled,
.ant-input-number-disabled {
  color: #262626 !important;

  input:disabled {
    color: #262626;
  }
}

.ant-select-disabled {
  .ant-select-selector {
    color: #262626 !important;
  }
}

.stars::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.search-form__card {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
