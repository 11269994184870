.notes_collapse {
  margin-bottom: 8px;

  &&.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    float: none;
  }
}

.additional-npi-collapse {
  &&.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 0;
  }

  &&.ant-collapse {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }

  .form-divider {
    &.ant-divider-horizontal.ant-divider-with-text::before {
      border: none;
    }
  }
}
