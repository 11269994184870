.auth-layout {
  min-height: 100vh;

  &__content {
    display: flex;
    align-items: center;
  }

  &__img {
    height: 100vh;
    background: url(../assets/login-img.svg) no-repeat right/cover;
  }
}

.search-layout {
  &__content {
    padding: 0 20px;
  }
  &__sidebar {
    background: #fff;
    padding-left: 16px;
    height: 100%;
    position: relative;
  }

  &__form-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    overflow-y: auto;
    //height: calc(100vh - 64px);
    padding: 16px 16px 0 0;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #e8e8e8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
    }
  }

  &__header {
    background-color: #fff;
    padding: 16px 24px;
    margin-bottom: 24px;

    .ant-breadcrumb + .ant-typography {
      margin: 4px 0 0;
    }
  }
}

.detail-page-layout {
  &__header {
    background-color: #fff;
    padding: 16px 0;

    .badge {
      font-size: 12px;
      color: #1890ff;
      font-weight: 600;
      position: relative;
      top: -10px;
      left: 3px;
    }

    .ant-typography {
      margin-bottom: 0;
    }
  }

  &__breadcrumbs {
    margin: 10px 0;
  }

  .title-badge {
    font-size: 12px;
    color: #1890ff;
    font-weight: 600;
    position: relative;
    top: -10px;
    left: 3px;
  }
}

.detail-page-layout__breadcrumbs {
  .badge {
    font-size: 10px;
    color: #1890ff;
    font-weight: 600;
    position: relative;
    top: -6px;
    left: 0px;
  }
}

.error-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.main-loading {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
