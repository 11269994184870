@success: @colors[green];

.ant-btn {
  &.button {
    &--success {
      border-color: darken(@success, 10%);
      background: darken(@success, 10%);

      &.ant-btn-background-ghost:not([disabled]) {
        color: darken(@success, 10%);

        &:hover,
        &:focus {
          color: darken(@success, 20%);
        }
      }

      &:hover,
      &:focus {
        border-color: darken(@success, 20%);
      }
    }
  }
}

.radio-button {
  .ant-radio-button-wrapper {
    padding: 0 8px;
    background: transparent;
    margin-right: 16px;
    height: auto;
    line-height: 24px;

    &-checked {
      background: #e6f7ff;
    }
  }
}
.role-details {
  display: flex;
  align-items: center;
}
