.login-form {
  max-width: 394px;
  margin-left: 94px;

  h1 {
    margin-bottom: 8px;
  }

  &__title {
    + form {
      margin-top: 24px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 24px;
    color: #262626;
    line-height: 32px;
  }

  .forgot-password-link {
    float: right;
  }
}

.sort-form {
  &__select-field {
    label {
      font-size: 12px;
      color: #8c8c8c;

      &.ant-form-item-no-colon {
        &:after {
          display: none;
        }
      }
    }
  }

  .ant-select {
    width: 200px;
  }
}

.ant-form-item-has-error {
  margin-bottom: 24px;
}

.form-input-icon {
  color: #1890ff;
}

.user-form {
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }

  &__label {
    font-size: 14px;

    &--status {
      font-weight: 600;
      line-height: 22px;
      color: #262626;
    }

    &--switch {
      margin-right: 8px;
      line-height: 26px;
    }
  }
}

.medical-form-wrapper {
  .ant-form-item-label label {
    width: 100%;
  }
}

.search-form {
  padding-bottom: 16px;

  &__card {
    background: #e8e8e8;
    border: 1px solid #bfbfbf;
    padding: 8px 8px 0;
    margin-bottom: 5px;

    &--compact {
      padding: 8px;
    }

    &--collapsible {
      .ant-form-item-label {
        & > label {
          display: block;
        }
      }
    }

    .ant-form-item-label {
      font-weight: 600;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }
  }

  &__divider {
    border-left: 2px solid #d9d9d9;
  }

  &__button {
    &-icon {
      font-size: 14px;
    }
  }
}

.ant-form {
  .form-divider {
    font-weight: 600;
  }
}

.ant-modal {
  .ant-form {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}

.activity-log-filter-form {
  margin-bottom: 20px;
}
