.font-face(@font-family, @file-path, @font-weight, @font-style) {
  @font-face {
    font-family: @font-family;
    src: url('@{file-path}.otf') format('opentype');
    font-weight: @font-weight;
    font-style: @font-style;
  }
}

.generate-card-colors(@colors, @className) {
  each(@colors, {
      .@{className}--@{key} {
        border-color: @value;

        .@{className}__head {
          background: fade(@value, 12%);
          color: darken(@value, 30%);
        }
      }
    }

  );
}
