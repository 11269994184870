.ant-tabs {
  width: 100%;

  .custom-tab-list {
    color: #8c8c8c;
    margin-bottom: 14px;

    &.light {
      background-color: #fff;
    }

    &.dark {
      .ant-tabs-nav {
        &:before {
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }

    .ant-tabs-tab {
      justify-content: center;
      min-width: 120px;
      font-size: 16px;
    }

    &__top-content {
      padding: 24px 20px 12px;
    }

    &__bottom-content {
      margin: 24px 0 0;
    }

    &.full-width {
      margin: 0 1px 14px;

      .ant-tabs-nav {
        flex: 1;
        max-width: 100%;
        padding: 0 20px;
      }
    }

    .ant-tabs-nav {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
      margin: 0 auto;
    }
  }

  &-small {
    .custom-tab-list {
      .ant-tabs-tab {
        min-width: 0;
        font-size: 14px;
      }

      &.full-width {
        .ant-tabs-nav {
          padding: 0;
        }
      }
    }
  }

  &-large {
    .custom-tab-list {
      &.full-width {
        .ant-tabs-nav {
          padding: 0;
        }
      }
    }
  }
}

.search-tab {
  padding: 0 24px;
}
