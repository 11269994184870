.history-log {
  .search-table {
    margin-bottom: 0;

    .ant-table-cell {
      white-space: normal;
    }

    .ant-table {
      margin-bottom: 0;
    }
  }
}

// is used as a temporary solution so that the last row
// in the table have enough space to display editable menus
.table-with-editable-cells .ant-table {
  padding-bottom: 32px;
}

.search-table {
  margin-bottom: 10px;
  width: 100%;

  .feedback-loop-edit-facility-buttons {
    button {
      width: 100%;
      &:first-child {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .ant-table-content table {
    border-collapse: collapse;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    color: #262626;

    &--count {
      font-weight: bold;
    }
    &--icon {
      color: #1890ff;
    }

    &--icon {
      font-size: 18px;
      margin-right: 12px;
    }
    &--action-text {
      margin-left: 12px;
    }
  }

  &__user-avatar {
    background-color: #e6fffb;
    border: 1px solid #87e8de;

    &--icon {
      color: #08979c;
    }
  }

  &__check-icon {
    color: #52c41a;
    font-size: 18px;
  }

  &__star-icon {
    font-size: 12px;
  }

  &__link-icon {
    font-size: 16px;
    color: #1890ff;
  }

  &__dropdown-icon {
    color: #8c8c8c;
  }

  &__cell-divider {
    border-left: 1px solid #bfbfbf;
  }

  &--compact {
    .ant-pagination {
      display: none;
    }
  }

  &__empty {
    .ant-empty-image {
      display: none;
    }

    .ant-empty-footer {
      margin: 0;
    }
    background-color: #fff;
    padding: 25px;
  }

  .case-info-table {
    .ant-table {
      padding: 0 2px;
    }
  }

  .ant-table {
    color: #262626;
    margin-bottom: 10px;
    overflow: auto;

    .ant-table-selection-column {
      text-align: left;
    }

    &.ant-table-small {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
        }

        .ant-table-cell {
          padding: 4px 10px;
        }
      }

      .ant-table-tbody,
      .ant-table-thead {
        background: #fff;
        td,
        th {
          &:first-child {
            padding-left: 16px;
          }
        }
      }
    }
  }

  .ant-table-column-sorter {
    display: none; // to remove default sort icon
  }

  .ant-table-column-has-sorters {
    &.ant-table-column-sort {
      font-weight: 600;
      color: #262626;

      .anticon {
        color: #1890ff;
      }
    }
  }

  &__footer {
    &--mb {
      margin-bottom: 20px;
    }
  }

  .one-string {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    //line-height: initial;
    vertical-align: bottom;
  }

  .bold {
    font-weight: bold;
  }

  .highlight__light_blue {
    background: #dedfff;
  }
  .highlight__medium_blue {
    background: #b8d9ff;
  }
  .highlight__strong_blue {
    background: #beedff;
  }

  .highlight__red {
    background: #ee7070;
  }

  .highlight__green {
    background: #bdffbd;
  }

  .case_info_row {
    .highlight__light_blue {
      background: transparent;
    }
    .highlight__medium_blue {
      background: transparent;
    }
    .highlight__strong_blue {
      background: transparent;
    }
  }

  .active_row {
    background: #e6f7ff;
    border: 2px solid #1890ff;

    .search_title {
      font-weight: bold;
      font-size: 15px;
    }

    .highlight__light_blue {
      background: #dedfff;
    }
    .highlight__medium_blue {
      background: #b8d9ff;
    }
    .highlight__strong_blue {
      background: #beedff;
    }
  }
}
.weekly-npees-updates {
  .search-table {
    margin-bottom: 0;
  }
}
.exceptions-table {
  .search-table {
    .ant-empty {
      margin: 0;
    }
  }
}

.roles-table {
  .centered {
    text-align: center;
  }

  .action-buttons-container {
    display: flex;
    justify-content: center;
  }
  .action-icon {
    color: #bfbfbf;
    font-size: 18px;
    padding: 0 10px;
  }
}

.roles-table-details {
  .ant-table-thead {
    tr {
      th {
        padding: 6px 6px;
      }
    }
  }

  table {
    .ant-table-tbody {
      tr {
        td {
          padding: 2px 6px;
        }
      }
    }
  }
}

.ant-table-cell {
  &.overflow-anywhere-cell {
    overflow-wrap: anywhere;
  }
}

.activityLogCardTable {
  .ant-table-thead > tr > th {
    padding-top: 0;
    padding-bottom: 6px;
  }

  .ant-table-tbody > tr > td {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.ant-table-tbody > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.aps-column-background {
  background-color: rgba(144, 209, 253, 0.1) !important;
}

.odr-column-background {
  background-color: rgba(250, 209, 139, 0.1) !important;
}


.ant-table-pagination.ant-pagination {
  margin: 10px 0;
}
