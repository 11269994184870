.logo {
  font-weight: 600;
  font-size: 24px;
  background: linear-gradient(89.94deg, #0099e6 0.05%, #00e6bf 99.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &__icon {
    color: #262626;
    background-color: #f1f1f1;
  }
}

.header {
  border-bottom: 1px solid #f0f0f0;

  &__sub {
    background-color: #fff;
  }

  &__user-dropdown {
    max-width: 200px;
  }
}

.ant-page-header {
  border: 1px solid #ebedf0;
  margin: 18px 0px;
}
